import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';


// import Slide8 from '../../assets/img/slide8.jpg';
/* import Slide10 from '../../assets/img/slide10.jpg'; */
// import Slide10 from '../../assets/img/Slide10-Correto.jpg';
// import Slide11 from '../../assets/img/Slide11.jpg';
// import Slide14 from '../../assets/img/slide14.jpg';
// import Slide15 from '../../assets/img/slide15.png';
//import Slide16 from '../../assets/img/slide16.png';
//import Slide17 from '../../assets/img/slide17.png';
//import BannerOreoDesktop from '../../assets/img/banner-desktop-site-oreo.jpg';
//import BannerNovosMilkShakesDesktop from '../../assets/img/2024/02/banner-site-milk-shakes-01-02-2024.jpg';
//import BannerEDDesktop from '../../assets/img/2024/04/banner-site-edicao-limitada.jpg';
import BannerTurmaDoChiquinhoDesktop from '../../assets/img/2024/09/banner-site-desktop-mes-das-criancas.jpg';
import BannerEspecialidadesChiquinhoDesktop from '../../assets/img/2025/01/banner-site-especialidades-ju-desktop.jpg';

import './styles.css';

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: false,
      arrows: false,
      draggable: false,
    };

    return (
      <Slider {...settings}>
 
        <div>
          <img src={BannerEspecialidadesChiquinhoDesktop} alt="Chiquinho Especialidades" />
        </div>
        
        <div>
          <a href='https://www.youtube.com/@turmadochiquinho' target='_blank'><img src={BannerTurmaDoChiquinhoDesktop} alt="Turma do Chiquinho" /></a>
        </div>

      </Slider>
    );
  }
}
